import './styles/main.css';
// import herminaProfilePic from './hermina.jpg';


function App() {
    return (<div>Coming soon...</div>);
    // return (
    //     <div className="bg-white">
    //         <div className="relative overflow-hidden">
    //
    //             <main>
    //                 <div className="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
    //                     <div className="mx-auto max-w-7xl lg:px-8">
    //                         <div className="lg:grid lg:grid-cols-2 lg:gap-8">
    //                             <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
    //                                 <div className="lg:py-24">
    //                                     <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
    //                                         <span className="block">Zonder gedoe</span>
    //                                         <span className="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-teal-200 to-cyan-400 sm:pb-5">
    //                    verzorg ik uw trainingen
    //                   </span>
    //                                     </h1>
    //                                     <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
    //                                         Online of op locatie. Trainingen die past bij de wensen van uw werknemers.
    //                                     </p>
    //                                 </div>
    //                             </div>
    //                             <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
    //                                 <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
    //                                     {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
    //                                     <img
    //                                         className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
    //                                         src={herminaProfilePic}
    //                                         alt=""
    //                                     />
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //
    //                 {/*/!* Testimonial section *!/*/}
    //                 {/*<div className="pb-16 bg-gradient-to-r from-teal-500 to-cyan-600 lg:pb-0 lg:z-10 lg:relative">*/}
    //                 {/*    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">*/}
    //                 {/*        <div className="relative lg:-my-8">*/}
    //                 {/*            <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"/>*/}
    //                 {/*            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">*/}
    //                 {/*                <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">*/}
    //                 {/*                    <img*/}
    //                 {/*                        className="object-cover lg:h-full lg:w-full"*/}
    //                 {/*                        src={herminaProfilePic}*/}
    //                 {/*                        alt=""*/}
    //                 {/*                    />*/}
    //                 {/*                </div>*/}
    //                 {/*            </div>*/}
    //                 {/*        </div>*/}
    //                 {/*        <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">*/}
    //                 {/*            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">*/}
    //                 {/*                <blockquote>*/}
    //                 {/*                    <div>*/}
    //                 {/*                        <p className="mt-6 text-2xl font-medium text-white">*/}
    //                 {/*                            Laat zorgenloos uw trainingen door mij uitvoeren.*/}
    //                 {/*                        </p>*/}
    //                 {/*                    </div>*/}
    //                 {/*                    <footer className="mt-6">*/}
    //                 {/*                        <p className="text-base font-medium text-cyan-100">Hermina Bootsma</p>*/}
    //                 {/*                    </footer>*/}
    //                 {/*                </blockquote>*/}
    //                 {/*            </div>*/}
    //                 {/*        </div>*/}
    //                 {/*    </div>*/}
    //                 {/*</div>*/}
    //
    //
    //                 {/* CTA Section */}
    //                 <div className="pt-5 lg:pt-0 relative bg-gray-900">
    //                     <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
    //                         <img
    //                             className="w-full h-full object-cover"
    //                             src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100"
    //                             alt=""
    //                         />
    //                         <div
    //                             aria-hidden="true"
    //                             className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600 mix-blend-multiply"
    //                         />
    //                     </div>
    //                     <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
    //                         <div className="md:ml-auto md:w-1/2 md:pl-10">
    //                             <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
    //                                 Vooraf een intake om zo al uw wensen door te nemen
    //                             </h2>
    //                             <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">Spontaniteit en helderheid</p>
    //                             <p className="mt-3 text-lg text-gray-300">
    //                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et, egestas tempus tellus etiam sed. Quam a
    //                                 scelerisque amet ullamcorper eu enim et fermentum, augue. Aliquet amet volutpat quisque ut interdum
    //                                 tincidunt duis.
    //                             </p>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </main>
    //             <footer className="bg-gray-50" aria-labelledby="footer-heading">
    //                 <h2 id="footer-heading" className="sr-only">
    //                     Footer
    //                 </h2>
    //                 <div className="max-w-md mx-auto pt-0 px-4 sm:max-w-7xl sm:px-6 lg:pt-0 lg:px-8">
    //                     <div className="mt-0 border-t border-gray-200 py-8">
    //                         <p className="text-base text-gray-400 text-center">&copy; 2021 Bootsma Trainingen</p>
    //                     </div>
    //                 </div>
    //             </footer>
    //         </div>
    //     </div>
    // )
}

export default App;
